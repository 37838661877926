// To animate cards when in new views
// cards_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card"];
  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: 0.1
    });
    
    this.cardTargets.forEach((card, index) => {
      console.log(card)
      this.observer.observe(card);
      card.style.transitionDelay = `${index * 100}ms`;
    });
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.style.transform = "translateY(0)";
        entry.target.style.opacity = "1";
      } else {
        entry.target.style.transform = "translateY(100px)";
        entry.target.style.opacity = "0";
      }
    });
  }

  disconnect() {
    this.observer.disconnect();
  }
}