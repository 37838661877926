import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["path"]

  copy(event) {
    event.preventDefault()
    event.stopPropagation()
    navigator.clipboard.writeText(this.pathTarget.textContent)
  }
}
