// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "../channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "../controllers"

document.addEventListener("turbolinks:load", function () {
  Rails.refreshCSRFTokens()

  // Direct-uploads events
  addEventListener("direct-upload:initialize", event => {

    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>
    `)
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
  })

  // Force click on input to load file
  let paoInput = document.querySelectorAll('#new_pao_visual input');

  paoInput.forEach(input => {
    input.addEventListener('change', (e) => {
      // Click to validate upload
      e.currentTarget.parentNode.parentNode.querySelector('[type="submit"]').click();
    })
  })

  // When file start loading
  addEventListener("direct-upload:start", event => {
    const { id } = event.detail
  })

  // When file is uploading
  addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`

    // Get cell to add percentage
    let changeIcon = event.target.parentNode.parentNode.parentNode.parentNode.querySelector('.status-cell');
    // Add percentage
    changeIcon.innerHTML = `${Math.floor(progress - 1)}%`
  })

  // When error
  addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  })

  // When ended
  addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")

    // Change icon to ✅
    let changeIcon = event.target.parentNode.parentNode.parentNode.parentNode.querySelector('.status-cell');
    changeIcon.innerHTML = "<i class='fe fe-check-circle text-success mt-2'></i>"
  })

  // When files are already uploaded
  let uploadedFiles = document.querySelectorAll('.pao-uploaded-file')
  uploadedFiles.forEach(uploadedFile => {
    // Dig parents elements
    let form = uploadedFile.parentNode.parentNode.parentNode
    let ctaBtn = uploadedFile.parentNode.querySelector('#pao_visual_file')

    // Hide text from button by reducing it
    ctaBtn.style.width = "118px"

    // Get cell and add icon
    let cell = form.querySelector('.status-cell');
    cell.innerHTML = "<i class='fe fe-check-circle text-success mt-2'></i>"

    // Remove element When file is already loaded
    ctaBtn.addEventListener('change', (e) => {
      // Clean cell
      e.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelector('.status-cell').innerHTML = "";
      // Hide CTA
      e.currentTarget.parentNode.parentNode.querySelector('.pao-uploaded-file').style.display = "none"
      // Auto click completion
      e.currentTarget.parentNode.parentNode.querySelector('[type="submit"]').click();
    })
  })

  // Ajoutez ce code à la fin du fichier

  function initializeFolderLink() {
    document.body.addEventListener('click', function(e) {
      if (e.target.closest('.folder-link')) {
        e.preventDefault();
        const folderLink = e.target.closest('.folder-link');
        console.log('Lien du dossier cliqué');
        const folderPath = folderLink.getAttribute('data-folder-path');
        console.log('Chemin du dossier:', folderPath);

        // Envoi du message à l'extension via window.postMessage
        window.postMessage({ type: 'OPEN_FOLDER', folderPath: folderPath }, '*');
      }
    });
  }

  document.addEventListener("turbolinks:load", function() {
    initializeFolderLink();
  });

  // Si vous voulez aussi l'initialiser au chargement initial du DOM
  document.addEventListener("DOMContentLoaded", function() {
    initializeFolderLink();
  });
})

const images = document.querySelectorAll('img')
if (images) {
  images.forEach(image => image.setAttribute('loading', 'lazy'))
}
const projectObserver = new IntersectionObserver((images) => {
  images.forEach((image) => {
      if (image.isIntersecting) {
        image.target.removeAttribute('loading', 'lazy')
      } else { }
  });
},
  {
    threshold: 1
  }
);

images.forEach((el, i) => {
  projectObserver.observe(el);
});
