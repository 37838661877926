import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formSignUp", "formSignIn", "buttonEmail", "passwordField", "signUpBtn"]
  
  showForm () {
    console.log("jol");
    // console.log(this.buttonEmailTarget);
    // this.formSignUpTarget.classList.remove('hidden')
    this.formSignInTarget.classList.add('hidden')
    // this.buttonEmailTarget.classList.add('hidden')
  }

  showCart() {
    console.log(this);
  }

  showPassword() {
    event.target.classList.add('hidden');
    this.passwordFieldTargets.forEach(password => password.classList.remove('hidden'));
    this.passwordFieldTargets[0].querySelector('input[type=password]').focus();
    this.signUpBtnTarget.classList.remove('hidden')
  }
}
