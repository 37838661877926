import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['actionsPanel']


  toggleAccordion(e) {

    const clickedElement = e.currentTarget;
    clickedElement.classList.toggle('max-h-fit');
    clickedElement.querySelector('.chevron').classList.toggle('rotate-180');

    const previouslyOpen = this.element.querySelector('.max-h-fit');
    if (previouslyOpen && previouslyOpen !== clickedElement) {
      previouslyOpen.classList.remove('max-h-fit');
      previouslyOpen.querySelector('.chevron').classList.remove('rotate-180');
    }
  }

  showActionsOnOrders(e) {
    this.actionsPanelTarget.classList.toggle('hidden');
  }

  hideActionsOnMouseOut(e) {
    console.log('out')
  }
}