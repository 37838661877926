import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "checkbox"]

  show() {
    // console.log(this.checkboxTarget.checked);
    if (this.checkboxTarget.checked){
      this.formTarget.classList.remove("hidden")
    }else{
      this.formTarget.classList.add("hidden")
    }
  }
}
