import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "output"]

  connect() {
    this.sync()
  }

  sync() {
    this.outputTarget.value = this.inputTarget.value
  }
}
