import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit(event) {
    // Récupérer le formulaire parent
    const form = event.target.closest('form')
    const statusCell = form.closest('tr').querySelector('.status-cell')
    const submitButton = form.querySelector('[type="submit"]')

    // Nettoyer la cellule de statut
    statusCell.innerHTML = '<div class="spinner-border text-primary" role="status"><span class="sr-only">Chargement...</span></div>'

    // Cacher le nom du fichier précédent s'il existe
    const uploadedFile = form.querySelector('.pao-uploaded-file')
    if (uploadedFile) {
      uploadedFile.style.display = 'none'
    }

    // Soumettre le formulaire
    submitButton.click()

    // Écouter l'événement de fin de téléchargement direct
    event.target.addEventListener('direct-upload:end', () => {
      statusCell.innerHTML = '<i class="fe fe-check-circle text-success mt-2"></i>'
    })

    // Gérer les erreurs potentielles
    event.target.addEventListener('direct-upload:error', () => {
      statusCell.innerHTML = '<i class="fe fe-x-circle text-danger mt-2"></i>'
    })
  }
}
