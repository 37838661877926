import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["iframe", "content"]
  player = null;

  connect() {
    if (window.YT && window.YT.Player) {
      // If the YT object and Player constructor are already available
      this.initializePlayer();
    } else {
      // Load the YouTube Iframe API script
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up a global function called when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        this.initializePlayer();
      };
    }
  }

  initializePlayer() {
    const videoId = this.data.get("video-id");
    this.player = new YT.Player(this.iframeTarget, {
      videoId: videoId, // Replace with your YouTube video ID
      events: {
        'onReady': (event) => this.onPlayerReady(event)
      }
    });
  }

  onPlayerReady(event) {
    // Player is ready
  }

  playVideo() {
    this.iframeTarget.classList.remove('hidden')
    this.contentTarget.classList.add('hidden')
    if (this.player) {
      this.player.playVideo();
    }
  }
}
