import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["societyField", "checkbox"] 
  connect() {
    this.checkboxTarget.checked ? this.societyFieldTarget.classList.remove('hidden') : this.societyFieldTarget.classList.add('hidden')
  }

  toggleSocietyField(event) {
    if(this.checkboxTarget.checked) {
      this.societyFieldTarget.classList.remove('hidden')
      this.societyFieldTarget.querySelector('input').focus()
    } else {   
      this.societyFieldTarget.classList.add('hidden')
    }
  }
}
