import { Controller } from "@hotwired/stimulus"
import { refreshCSRFTokens } from "@rails/ujs"
import { useDebounce } from 'stimulus-use'
const morphdom = require('morphdom').default;

export default class extends Controller {
  static targets = ["mobileRecap", "parameterInput", "submitButton", "toggleMobileFormButton", "openMenuButton", "quantityInput"]

  static values = {
    refreshPriceUrl: String
  }

  static debounces = ['debouncedRefreshForm']

  connect() {
    if (this.element.dataset.formOpen == 'true') {
      this.openComposerOnMobile()
    }
    useDebounce(this, { wait: 400 })
  }

  // TODO: prevent HTTP call when input value has not changed (i.e user has paused while editing but not make any value changes)
  // Will need the composer_controller to memoize input value

  refreshForm(event) {

    if (this.anyParameterInputsEmpty()) {
      this.disableSubmit()
    } else {
      fetch(this.refreshPriceUrlValue, {
        method: "POST",
        headers: { "Accept": "text/plain" },
        body: new FormData(this.element),
      })
        .then(response => response.text())
        .then((data) => {
          morphdom(this.element, data);

          refreshCSRFTokens()
        })
    }
  }

  debouncedRefreshForm(event) {
    this.refreshForm(event)
  }

  anyParameterInputsEmpty() {
    return this.parameterInputTargets.some(input => {
      return input.value.length == 0
    })
  }

  disableSubmit() {
    this.submitButtonTarget.disabled = true
  }

  get form() {
    return this.element
  }

  addItemstoQuantity(event) {
    let currentValue = Number(this.quantityInputTarget.value)
    if (isNaN(currentValue)) {
      currentValue = 0;
    }
  
    this.quantityInputTarget.value = currentValue + 100;
    this.refreshForm(event)
  }

  focusInput(e) {
    const input = e.currentTarget.querySelector('input');
    const select = e.currentTarget.querySelector('.select select');

    if (input) {
        input.focus();
    } else if (select) {
        const selectWrapper = select.closest('.select');
        if (selectWrapper) {
            selectWrapper.classList.add('is-active');
            select.focus(); // Focus on the select element
            select.click(); // This will open the select dropdown
        }
    }
  }
}
