import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element) {
      this.element.addEventListener('click', this.handleClick.bind(this))
    }
  }

  handleClick(e) {
    const folderLink = e.target.closest('.folder-link')
    if (!folderLink) return

    e.preventDefault()
    const folderPath = folderLink.getAttribute('data-folder-path')
    console.log('Chemin du dossier:', folderPath)
    window.postMessage({ type: 'OPEN_FOLDER', folderPath: folderPath }, '*')
  }
}
