// src/controllers/hello_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menuMobile", "menuMobileContent", "overlayMobile", "menuDesktop", "megaMenuImage", 'menuDesktopOverlay', "menuIcon"]

  mobileMenuOpen(e) {
    if (this.menuMobileTarget.classList.contains('hidden')) {
      this.menuMobileTarget.classList.remove('hidden')
      setTimeout(() => this.menuMobileContentTarget.style.transform = 'translateX(0px)', 1)
      //setTimeout(() => this.menuMobileContentTarget.classList.toggle('translate-x-[-320px]'), 1000)
    } else {
      //this.menuMobileContentTarget.classList.toggle('translate-x-[-320px]')
      setTimeout(() => this.menuMobileContentTarget.style.transform = 'translateX(-320px)', 1)
      setTimeout(() => this.menuMobileTarget.classList.add('hidden'), 300)
    }

    this.menuIconTarget.classList.contains('fe-bar') ? this.menuIconTarget.classList.toggle('fe-close') : this.menuIconTarget.classList.toggle('fe-bar') ;
  }

  clickOverlay(e) {
    this.mobileMenuOpen(e)
  }

  showMegaMenuDesktop() {
    this.menuDesktopOverlayTarget.classList.remove('hidden')
    this.menuDesktopTarget.style.transform = "translateY(-400px)"
    this.menuDesktopTarget.classList.remove('hidden')
    this.timeout = window.setTimeout(() => {
      this.menuDesktopTarget.style.transform = "translateY(0px)"
      this.menuDesktopOverlayTarget.style.opacity = '70%'
    }, 1)
    // TODO load image
    let url = "https://www.autocollant-personnalise.com/static/images/autocollant-personnalise-decoupe-a-unite.webp"
    this.megaMenuImageTarget.src = url
  }
  closeMegaMenuDesktop() {
    this.menuDesktopOverlayTarget.style.opacity = '0%'
    this.menuDesktopTarget.style.transform = "translateY(-400px)"
    setTimeout(() => {
      this.menuDesktopTarget.classList.add('hidden');
      this.menuDesktopOverlayTarget.classList.add('hidden')
    }, 350)
  }

  firstImage() {
    let url = "https://www.autocollant-personnalise.com/static/images/autocollant-personnalise-decoupe-a-unite.webp"
    this.megaMenuImageTarget.src = url
  }

  secondImage() {
    let url = "https://www.autocollant-personnalise.com/static/images/impression-stickers-en-planche-personnalise.webp"
    this.megaMenuImageTarget.src = url
  }
  clearTimeout() {
    if (this.timeout) window.clearTimeout(this.timeout)
  }
}
