import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["cartContent", "cartDetail", "cartDetailOpenLink", "cartPromoCode"]

  showCart(e) {
    // Animation for opening / closing menu
    this.cartContentTargets.forEach((target, index) => {
      let ms = index * 100
      setTimeout(() => target.classList.toggle('hidden'), ms)
    })

    let arrowDown = e.currentTarget.querySelector('.fe-drop-right')
    arrowDown.classList.toggle('rotate-90')
  }

  showOrderDetails(e) {
    let cartDetail = e.currentTarget.querySelector('[data-cart-target="cartDetail"]');
    let cartDetailOpenLink = e.currentTarget.querySelector('[data-cart-target="cartDetailOpenLink"]');
    let arrowDown = e.currentTarget.querySelector('.fe-drop-right');

    arrowDown.classList.toggle('rotate-90');
    cartDetailOpenLink.classList.toggle('opacity-50');
    cartDetail.classList.toggle('opacity-0');
    cartDetail.classList.toggle('h-0');
  }
}
