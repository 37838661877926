import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  fadeContent(event) {
    event.preventDefault()

    // Select only after url and match /checkout or /shipping variants
    let reg = /http:\/\/([\w.-]+|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}):(\d+)\/([\w\/]+)/
    
    // Select only end of the url to pass it later
    let path = event.currentTarget.href.match(reg)[3]
    
    // Fade the content
    const fadeInWrapper =  document.body.querySelector('.animate-fadein')

    if (fadeInWrapper) {
      fadeInWrapper.classList.add('animate-fadeout')
      // Go to url after 100ms
      setTimeout(() => window.location = `${window.location.origin}/${path}`, 100)
    }
  }
}
