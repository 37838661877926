import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["composer", "fixedCTA"]

  connect() {
    const footer = document.querySelector('footer');
    const observerOptions = {
      threshold: 0 
    };

    const toggleClass = (entry) => {
      this.fixedCTATarget.classList.toggle('translate-y-[56px]', entry.isIntersecting);
    };

    const observerIn = new IntersectionObserver(entries => {
      entries.forEach(toggleClass);
    }, observerOptions);

    const observerOut = new IntersectionObserver(entries => {
      entries.forEach(toggleClass);
    }, observerOptions);

    observerIn.observe(this.composerTarget);
    observerOut.observe(footer);
  }
}
